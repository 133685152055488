<script>
  // @ts-nocheck

  import { getContext, createEventDispatcher } from "svelte";
  import cloneDeep from "lodash/cloneDeep";
  import isEmpty from "lodash/isEmpty";
  import isEqual from "lodash/isEqual";
  import {
    hydrateItem,
    createType as defaultType,
    createItem as defaultItem,
    hydrateType,
  } from "@local/lamina-core";
  import liteDrawingMini from "@local/extensions/drawing/lite-drawing-mini.js";
  import { typeColorTrue } from "@local/extensions/drawing/type-color.js";

  import CaretLeftIcon from "src/assets/icons/caret-left.svg";
  import { Modal } from "svelte-utilities";
  import FilterInput from "src/lib/products/FilterInput.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import LibrarySection from "src/lib/sidebar/LibrarySection.svelte";
  import MakeupThumbnail from "src/lib/MakeupThumbnail.svelte";
  import DimtextInput from "./sidebar/DimtextInput.svelte";
  import TextInput from "./sidebar/TextInput.svelte";
  import Viewport from "src/lib/drawing/ViewportFixed.svelte";

  import { recordFilter } from "src/extensions/makeup-library.js";
  import imageUrl from "src/extensions/image-url.js";
  import { parseItemScript, parseItemParams } from "src/extensions/importers/item-script.js";
  import { profile } from "src/stores/auth.js";
  import { api } from "src/api";

  export let group;
  export let types;
  export let items;
  export const open = (prop) => openModal(prop);

  const dispatch = createEventDispatcher();

  let modal;
  let category = null;
  let filter = "";
  let product = null;
  let itemProductEntry;
  let specs = [];
  let specOptions = [];

  let newItem;
  let itemProduct;
  let params = [];

  let selectedCategory = null;

  const makeupLibrary = getContext("makeupLibrary");
  const suppliers = getContext("suppliers");
  const supplier = getContext("supplier");

  const inputs = {
    text: TextInput,
    dimtext: DimtextInput,
    select: SelectInput,
  };

  $: makeups = $makeupLibrary.map((m) => m.records).flat();
  $: settings = $group?.data.settings;
  $: sections = category
    ? $makeupLibrary?.filter((s) => s.title === category || s.id === category)
    : $makeupLibrary;
  $: filteredProducts = makeFP(sections, filter, $supplier);
  $: updateSpecOptions(specs);
  $: usedTypes = $group.items.order.reduce((a, id) => {
    const item = $group.items[id];
    if (item?.type_id) {
      a[item?.type_id] = true;
    }
    return a;
  }, {});
  $: itemPreview = makeItemPreview(newItem, itemProductEntry, params, itemProduct);

  function makeFP(sections, filter, supplier) {
    const categories = sections?.map((section) => {
      const category = supplier?.product_categories[section.id];

      return {
        ...section,
        image: category?.image,
        records: section.records.filter(recordFilter(filter)),
      };
    });

    return {
      records: categories,
    };
  }

  function openModal(prop) {
    product = null;
    selectedCategory = null;
    specs = [];
    params = [];
    newItem = null;
    itemProduct = null;
    itemProductEntry = null;
    if (prop?.category) {
      category = prop.category;
    } else {
      category = null;
    }
    modal.open();
  }

  async function updateSpecOptions(specs) {
    if (specs.length) {
      const options = await api.rpc("product_query_options", { makeup: specs[0].makeup });
      if (options.data) {
        specOptions = options.data;
      } else {
        specOptions = [];
      }
    }
  }

  function productSpecs(product) {
    if (!product.data.layers) return [];
    return product.data.layers.reduce((a, layer, lindex) => {
      if (layer.type === "glass") {
        if (layer.material?.type === "query") {
          a.push({
            index: lindex,
            name: "Material",
            path: "material",
            makeup: layer.material,
          });
        }

        if (layer.outboard_surface?.type === "query") {
          a.push({
            index: lindex,
            name: "Outboard Surface",
            path: "outboard_surface",
            makeup: layer.outboard_surface,
          });
        }

        if (layer.inboard_surface?.type === "query") {
          a.push({
            index: lindex,
            name: "Inboard Surface",
            path: "inboard_surface",
            makeup: layer.inboard_surface,
          });
        }
      }

      return a;
    }, []);
  }

  function selectType(p) {
    itemProductEntry = p;

    product = hydrateType({
      ...defaultType($group.id, types),
      name: p.name,
      image: p.image,
      data: cloneDeep(p.data),
      category: p.category,
      category_id: p.category_id,
      product_id: p.id,
      item_template: p.item_template,
      item_template_mask: p.item_template_mask,
      use_item_drawing_as_thumbnail: p.use_item_drawing_as_thumbnail,
    });

    specs = productSpecs(product);

    if (!specs.length) {
      const existing = types.find((t) => {
        return p.id === t.product_id && usedTypes[t.id];
      });

      const item = {
        ...defaultItem($group.id, $profile.id, items, types, { type_id: existing?.id || product.id }),
        ...product.item_template,
      };

      if (item.shape.type === "none") {
        item.mark = null;
      }

      if (p.item_script && !isEmpty(p.item_script_decl?.input)) {
        newItem = item;
        itemProduct = product;
        return getParameters(p);
      }

      if (existing) {
        const existingItem = items.find((i) => i.type_id === existing.id && !i.data.meta_parameters);
        dispatch("addProduct", { item_id: existingItem?.id, type_id: existing.id, product, item });
      } else {
        dispatch("addProduct", { product, item });
      }

      modal.close();
    }
  }

  function getParameters(p) {
    const decl = p.item_script_decl;

    params = parseItemParams(decl.input);
  }

  function cancelParams() {
    modal.close();
  }

  function makeItemPreview(newItem, itemProductEntry, params, itemProduct) {
    if (!newItem || !itemProductEntry || !params || !itemProduct) return null;
    if (params.some((p) => p.value === undefined)) return null;

    const typeColor = typeColorTrue(itemProduct);

    const inputParams = params.reduce((a, p) => {
      a[p.key] = p.value;
      return a;
    }, {});

    const script = parseItemScript(itemProductEntry.item_script);
    const item = script.run(newItem, inputParams);
    const hydrated = hydrateItem(item);
    return liteDrawingMini(hydrated, { typeColor });
  }

  function addProductWithParams() {
    const inputParams = params.reduce((a, p) => {
      a[p.key] = p.value;
      return a;
    }, {});
    const script = parseItemScript(itemProductEntry.item_script);
    const item = script.run(newItem, inputParams);
    item.data.meta_parameters = inputParams;

    if ($group.types[item.type_id]) {
      const existingItem = items.find(
        (i) => i.type_id === item.type_id && isEqual(i.data.meta_parameters, item.data.meta_parameters),
      );
      dispatch("addProduct", {
        item_id: existingItem?.id,
        type_id: item.type_id,
        product: itemProduct,
        item,
      });
    } else {
      dispatch("addProduct", { product: itemProduct, item });
    }
    modal.close();
  }

  function selectSpec(e) {
    const s = specs[0];
    const layer = product.data.layers[s.index];
    layer[s.path] = {
      ...layer[s.path],
      ...e.detail.record,
    };

    specs = specs.slice(1);

    if (!specs.length) {
      const ps = productSpecs(product);
      const existing = types.find((t) => {
        if (t.product_id !== product.product_id) return false;

        return ps.every((s) => {
          const np = product.data.layers[s.index]?.[s.path];
          const tp = t.data.layers?.[s.index]?.[s.path];
          return np?.id === tp?.id && usedTypes[t.id];
        });
      });

      const item = {
        ...defaultItem($group.id, $profile.id, items, types, { type_id: existing?.id || product.id }),
        ...product.item_template,
      };

      if (item.shape.type === "none") {
        item.mark = null;
      }

      if (itemProductEntry.item_script && !isEmpty(itemProductEntry.item_script_decl?.input)) {
        newItem = item;
        itemProduct = product;
        return getParameters(itemProductEntry);
      }

      if (existing) {
        const existingItem = items.find((i) => i.type_id === existing.id && !i.data.meta_parameters);
        dispatch("addProduct", { item_id: existingItem?.id, type_id: existing.id, product, item });
      } else {
        dispatch("addProduct", { product, item });
      }
      modal.close();
    }
  }

  function selectCategory(e) {
    selectedCategory = e.detail.record;
  }

  function updateSupplier(e) {
    dispatch("updateSupplier", e.detail);
  }
</script>

<Modal width="36rem" bind:this={modal} closeable fullframe>
  <div slot="title">Add Product</div>
  <div slot="content" class="overflow-y-auto">
    <div class="p-4 space-y-2 text-xs">
      {#if !product}
        <div class="mb-4">
          <FilterInput bind:filter />
        </div>
        {#if $suppliers?.length > 1}
          <div>Choose a supplier:</div>
          <SelectInput
            border
            label="Supplier"
            value={$supplier?.id}
            options={$suppliers.map((s) => ({ value: s.id, label: s.name }))}
            on:input={updateSupplier} />
        {/if}
        {#if makeups.length}
          {#if !selectedCategory}
            <div>Select a product category:</div>
            <LibrarySection section={filteredProducts} on:select={selectCategory}>
              <div slot="thumbnail" class="relative w-full h-full" let:record>
                <img
                  class="w-full h-full object-cover text-xs"
                  src={imageUrl(record.image)}
                  alt={record.title} />
              </div>
              <div slot="caption" let:record>
                <div>
                  {record.title}
                </div>
                <div class="italic">{record.records.length} products</div>
              </div>
            </LibrarySection>
          {:else}
            <button class="btn-text flex items-center gap-1" on:click={() => (selectedCategory = null)}>
              <CaretLeftIcon />
              <div>All Categories</div>
            </button>
            <LibrarySection
              section={selectedCategory}
              on:select={(e) => selectType(e.detail.record)}
              collapsible={false}>
              <MakeupThumbnail slot="thumbnail" let:record {record} />
              <div slot="caption" let:record>{record?.name}</div>
            </LibrarySection>
          {/if}
        {:else}
          <div class="italic">No products</div>
        {/if}
      {:else if specs.length}
        {@const spec = specs[0]}
        <button class="btn-text flex gap-2 items-center" on:click={() => (product = null)}>
          <CaretLeftIcon />
          <div>Select Product</div>
        </button>
        <div class="w-32 rounded border border-gray-300 bg-white p-2 shadow">
          <div class="w-full h-20 border relative">
            <MakeupThumbnail record={product} />
          </div>
          <div class="text-xxs">
            {product.name}
          </div>
        </div>
        <div class="pt-2">
          Choose {spec.name}:
        </div>
        <LibrarySection section={{ records: specOptions }} on:select={selectSpec}>
          <div
            slot="thumbnail"
            let:record
            class="w-full h-full"
            style="background-color: {record?.color || '#FFFFFF'}">
            {#if record.image}
              <img
                src={imageUrl(record.image)}
                alt={record.name}
                class="text-xs w-full h-full object-cover" />
            {/if}
          </div>
          <div slot="caption" let:record>
            {record.name}
          </div>
        </LibrarySection>
      {:else if params.length}
        <button class="btn-text flex gap-2 items-center" on:click={() => (product = null)}>
          <CaretLeftIcon />
          <div>Select Product</div>
        </button>
        <div class="flex gap-2 items-center">
          <div class="flex-none relative w-64 h-64">
            {#if itemPreview}
              <Viewport padding={16} drawing={itemPreview} width={256} height={256} />
            {:else}
              <div class="w-full h-full bg-gray-100 flex items-center justify-center">
                <div class="italic text-xs">No preview</div>
              </div>
            {/if}
          </div>
          <div class="grow w-48 space-y-2">
            {#each params as param}
              <svelte:component
                this={inputs[param.type]}
                border
                labelWidth="8rem"
                label={param.label}
                bind:value={param.value}
                options={param.options}
                {settings} />
            {/each}
          </div>
        </div>
        <div class="flex justify-end gap-4">
          <button class="btn btn-cancel" on:click={cancelParams}> Cancel </button>
          <button
            class="btn btn-primary"
            on:click={addProductWithParams}
            disabled={params.some((p) => p.value === undefined)}>
            Add Product
          </button>
        </div>
      {/if}
    </div>
  </div>
</Modal>
