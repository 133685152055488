<script>
  import { getContext } from "svelte";

  import ProductSummaryTableType from "./ProductSummaryTableType.svelte";
  import NewProductModal from "src/lib/NewProductModal.svelte";
  import groupItems from "@local/extensions/collections/group-items.js";
  import sortByCategory from "@local/extensions/utilities/sort-by-category.js";
  import { rfqs } from "src/stores/rfqs.js";
  import { jobs } from "src/stores/jobs.js";
  import { quotes } from "src/stores/quotes.js";

  export let org;
  export let group;
  export let items;
  export let types;
  export let sent = false;
  export let job;
  export let quote;
  export let recordId;
  export let recordType = "rfq";

  const priceEntries = getContext("priceEntries");
  const supplier = getContext("supplier");

  let newProductModal;

  $: productGroup = $group?.project_type === "product";
  $: grouped = groupItems(items);
  $: ordered = getOrderedItems(grouped, types, productGroup, $supplier);
  $: counts = getCounts(ordered);
  $: area = sumArea(items);
  $: weight = sumWeight(items);
  $: shipping = getShipping(job, quote, $supplier, weight);
  $: qty = items.reduce((acc, item) => acc + item.quantity, 0);
  $: price = sumPrice($group, items, $priceEntries, job, quote);
  $: totalPrice = computeTotalPrice(quote, sent, price, shipping);

  function getShipping(job, quote, $supplier, weight) {
    if (sent && quote?.data?.shipping != null) {
      return quote.data.shipping / 100;
    }

    return null;
  }

  function sumArea(items) {
    const a = items.reduce((acc, item) => {
      return acc + (item.cache?.area || 0);
    }, 0);

    return `${a.toFixed(2)} sf`;
  }

  function sumWeight(items) {
    return items.reduce((acc, item) => {
      return acc + (item.cache?.weight || 0);
    }, 0);
  }

  function getArea(item) {
    return item.width && item.height ? (item.cache.width / 12) * (item.cache.height / 12) : 0;
  }

  function sumPrice(grp, items, priceEntries, job, quote) {
    if (sent && quote?.data?.price_subtotal != null) {
      return quote.data.price_subtotal / 100;
    }

    return items.reduce((acc, item) => {
      const type = grp.types[item.type_id];
      if (!type || !priceEntries || !job?.product_list_id) return acc;
      const priceEntry = priceEntries[type.product_id]?.[job?.product_list_id];

      if (priceEntry?.unit === "item") {
        return acc + priceEntry.unit_price * item.quantity;
      } else if (priceEntry?.unit === "sqft") {
        return acc + priceEntry.unit_price * getArea(item) * item.quantity;
      } else if (priceEntry?.unit === "sqin") {
        return acc + priceEntry.unit_price * getArea(item) * 144 * item.quantity;
      } else if (priceEntry?.unit === "m2") {
        return acc + priceEntry.unit_price * getArea(item) * 0.092903 * item.quantity;
      } else {
        return acc;
      }
    }, 0);
  }

  function computeTotalPrice(quote, sent, price, shipping) {
    if (sent && quote?.price) {
      return quote.price / 100;
    }

    return (price || 0) + (shipping || 0);
  }

  function getOrderedItems(grouped, types, productGroup, supplier) {
    const o = [];
    let index = 0;

    let t;

    if (productGroup) {
      if (supplier?.product_categories) {
        t = sortByCategory(types, supplier.product_categories);
      } else {
        t = types;
      }

      t = t.filter((t) => grouped[t.id]?.length);
    } else {
      t = types;
    }

    t.forEach((type) => {
      const items = grouped[type.id] || [];

      o.push({
        type,
        items,
        index,
      });

      index += items.length;
    });

    if (grouped.untyped.length) {
      o.push({
        type: null,
        items: grouped.untyped,
        index,
      });
    }

    return o;
  }

  function getCounts(ordered) {
    const counts = [0];
    ordered.slice(1).forEach((t, ti) => {
      const current = counts[ti];
      const prev = ordered[ti].items.length;
      counts.push(current + prev);
    });
    return counts;
  }

  function updateQuantity(e) {
    const { item, quantity } = e.detail;
    group.updateItem(item.id, { quantity });
  }

  function deleteItem(e) {
    const { item } = e.detail;
    group.removeItem(item.id);
  }

  function beginAddingProduct() {
    newProductModal.open({ category: null });
  }

  async function addProduct(e) {
    const { item_id, type_id, product, item } = e.detail;

    if (item_id) {
      await group.updateItem(item_id, "quantity", (item.quantity || 0) + 1);
    } else if (type_id) {
      await group.addItem(item);
    } else {
      await group.addType(product);
      await group.addItem(item);
    }

    if (recordType === "rfq") {
      rfqs.fetchOne(recordId);
    } else if (recordType === "quote") {
      quotes.fetchOne(recordId);
    } else if (recordType === "job") {
      jobs.fetchOne(recordId);
    }
  }
</script>

<div class="px-4 text-xs overflow-x-auto">
  <table class="outline-none w-full">
    <tr class="border-b border-t">
      <th>Line</th>
      <th>Product Info</th>
      <th class="numeric">Area</th>
      <th class="numeric">Weight</th>
      <th class="numeric">Price (EA)</th>
      <th class="numeric">Qty</th>
      <th class="numeric">Price</th>
      {#if !sent}
        <th></th>
      {/if}
    </tr>
    {#each ordered as t, ti (t.type.id)}
      {#if t.type}
        <ProductSummaryTableType
          {sent}
          {group}
          type={t.type}
          items={t.items}
          typeIndex={counts[ti]}
          index={t.index}
          {job}
          on:update-quantity={updateQuantity}
          on:delete-item={deleteItem} />
      {/if}
    {/each}
    {#if !sent}
      <tr>
        <td colspan="8" class="text-center p-2">
          <button
            class="h-16 w-full rounded border text-blue-500 border-dashed border-gray-300 hover:bg-gray-100"
            on:click={beginAddingProduct}>
            + Add Product
          </button>
        </td>
      </tr>
    {/if}
    <tr class="border-b border-t">
      <th></th>
      <th></th>
      <th class="numeric">Area</th>
      <th class="numeric">Weight</th>
      <th></th>
      <th class="numeric">Qty</th>
      <th class="numeric">Price</th>
    </tr>
    <tr class="totals">
      <td colspan="2">Subtotal</td>
      <td class="numeric">{area}</td>
      <td class="numeric">{weight?.toFixed(0) ?? ""}</td>
      <td class="numeric"></td>
      <td class="numeric">{qty}</td>
      <td class="numeric">{price?.toFixed(2) ?? ""}</td>
    </tr>
    {#if sent && quote}
      <tr class="totals">
        <td colspan="2">Shipping</td>
        <td></td>
        <td></td>
        <td></td>
        <td colspan="2" class="numeric">
          {shipping?.toFixed(2) ?? "N/A"}
        </td>
      </tr>
      <tr class="totals">
        <td colspan="2">Total</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td class="numeric">
          {totalPrice?.toFixed(2) ?? ""}
        </td>
      </tr>
    {:else}
      <tr class="totals">
        <td colspan="2"></td>
        <td></td>
        <td></td>
        <td></td>
        <td colspan="2" class="numeric">Shipping TBD</td>
      </tr>
    {/if}
  </table>
</div>

<NewProductModal
  bind:this={newProductModal}
  {group}
  {items}
  {types}
  on:updateSupplier
  on:addProduct={addProduct} />

<style lang="scss">
  table {
    min-width: 36rem;
    margin: 0 auto;
  }

  th {
    text-align: left;
    padding: 0.25rem;
    white-space: nowrap;

    &.numeric {
      text-align: right;
    }
  }

  .totals td {
    @apply whitespace-nowrap;
    padding: 0rem 0.25rem 0rem 0.25rem;
    font-weight: bold;
    height: 2rem;

    &.numeric {
      text-align: right;
    }
  }
</style>
