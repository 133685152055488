import { defineItemScript } from "@local/lamina-script";
import { DimText } from "dimtext";

const dt = new DimText();

function parseItemScript(script) {
  const body = script
    .replace(/^\s*export\s+default\s+.*?;?\s*$/gm, "")
    .replace(/^\s*import\s.*?;?\s*$/gm, "")
    .replace(/^\s*export\s.*?;?\s*$/gm, "");

  try {
    return new Function(
      "defineItemScript",
      `
			"use strict";
			${body}
			return script;
		`,
    )(defineItemScript);
  } catch (e) {
    console.error(e);
    return null;
  }
}

function parseItemParams(input) {
  return Object.keys(input).map((key) => {
    const d = input[key];
    let value = d.default;
    let type = d.type;
    let options = [];
    if (d.type === "dimtext" && typeof d.default === "string") {
      value = dt.parse(d.default).value;
    }

    if (d.constraint?.type === "choice") {
      type = "select";
      options = d.constraint.values.map((v) => {
        let oval = v.value;
        if (d.type === "dimtext" && typeof oval === "string") {
          oval = dt.parse(oval).value;
        }

        return {
          label: v.name,
          value: oval,
        };
      });
    }

    return {
      key,
      type,
      label: d.label || key,
      options,
      value,
    };
  });
}

export { parseItemScript, parseItemParams };
