<script>
  import { onMount, onDestroy, getContext, setContext } from "svelte";
  import { writable, derived } from "svelte/store";
  import { downloadBlob } from "downloaders";
  import isEqual from "lodash/isEqual";
  import upperCase from "lodash/upperCase";

  import Bugsnag from "src/bugsnag.js";

  import CirclePlusIcon from "src/assets/icons/circle-plus.svg";
  import TagIcon from "src/assets/icons/tag.svg";
  import ProfileIcon from "src/assets/icons/profile.svg";
  import LocationIcon from "src/assets/icons/location.svg";
  import NotesIcon from "src/assets/icons/notes.svg";
  import NumberIcon from "src/assets/icons/number.svg";
  import GroupAttachments from "./GroupAttachments.svelte";
  import ContenteditableInput from "src/lib/sidebar/ContenteditableInput.svelte";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import RecipientsInput from "./RecipientsInput.svelte";
  import MapboxInput from "src/lib/sidebar/MapboxInput.svelte";

  import { Modal } from "svelte-utilities";
  import Quote from "./Quote.svelte";
  import QuoteRecipientModal from "./QuoteRecipientModal.svelte";
  import ProductSendModal from "./ProductSendModal.svelte";
  import DownloadModal from "./DownloadModal.svelte";
  import ProductSummaryTable from "./ProductSummaryTable.svelte";
  import Notifications from "./Notifications.svelte";

  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { rfqs, fetchRfq } from "src/stores/rfqs.js";
  import { orgs } from "src/stores/organizations.js";
  import { fetchGroup, createGroup } from "src/stores/group.js";
  import { profile } from "src/stores/auth.js";
  import { typeColor, typeColorTrue } from "@local/extensions/drawing/type-color.js";
  import eb from "src/extensions/event-bus.js";
  import { api, sendQuoteRequest, addQuoteRequestRecipients, cancelQuoteRequest } from "src/api";
  import { currentSupplierid } from "src/stores/ui.js";
  import { bucketArrayMultiple } from "overline";
  import getUserInitials from "@local/extensions/formatters/get-user-initials.js";
  import { orgLibrary } from "src/extensions/makeup-library.js";

  export let rfqid;

  const typeColors = writable({});
  const typeColorsTrue = writable({});

  let rfq;
  let qrModal;
  let cancelRfqModal;
  let group;

  function transform(coll, xf, defaultValue = []) {
    return coll ? xf(coll) : defaultValue;
  }

  const suppliers = getContext("suppliers");
  const supplier = getContext("supplier");
  const org = getContext("org");
  const productLists = getContext("productLists");

  const makeupLibrary = derived(supplier, ($supplier) => orgLibrary($supplier, $group?.project_type));

  setContext("typeColors", typeColors);
  setContext("typeColorsTrue", typeColorsTrue);
  setContext("makeupLibrary", makeupLibrary);

  $: recipients = rfq?.data.saved_recipients || [];
  $: items = transform($group?.items, orderedList).filter((i) => !i.is_collection);
  $: types = transform($group?.types, orderedList);
  $: $typeColors = types.reduce((a, t) => {
    a[t.id] = typeColor(t);
    return a;
  }, {});
  $: $typeColorsTrue = types.reduce((a, t) => {
    a[t.id] = typeColorTrue(t);
    return a;
  }, {});
  $: assignToOptions = getAssignToOptions($profile, $org);
  $: allAttachments = $group && bucketArrayMultiple($group?.attachments, "item_id", "type_id");
  $: firstQuote = rfq?.quotes?.[0];

  function confirmAddRecipients() {
    qrModal.open();
  }

  function getAssignToOptions(p, o) {
    if (!o) return [];

    const orgProfiles =
      o.profiles?.map((prof) => ({
        label: prof.username || prof.email,
        initials: upperCase(getUserInitials(prof)),
        color: prof.user_color,
        value: prof.id,
      })) || [];

    const present = orgProfiles.some((prof) => prof.value === p.id);

    if (present) {
      return [
        {
          label: "Unassigned",
          value: null,
        },
        ...orgProfiles,
      ];
    } else {
      return [
        {
          label: "Unassigned",
          value: null,
        },
        {
          label: p.username || p.email,
          initials: upperCase(getUserInitials(p)),
          color: p.user_color,
          value: p.id,
        },
        ...orgProfiles,
      ];
    }
  }

  async function addRecipients(e) {
    const { recipients } = e.detail;

    const existingRecipients = rfq.data.saved_recipients || [];
    const saved_recipients = [...existingRecipients, ...recipients];

    await api
      .from("quote_requests")
      .update({
        data: { saved_recipients },
      })
      .eq("id", rfqid);

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function setRecipient(e) {
    const { recipient } = e.detail;
    const saved_recipients = [recipient];
    await api
      .from("quote_requests")
      .update({
        data: { saved_recipients },
      })
      .eq("id", rfqid);

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function removeReipient(e) {
    const { id } = e.detail;
    const saved_recipients = rfq.data.saved_recipients.filter((r) => r.id !== id);

    await api.from("quote_requests").update({ data: { saved_recipients } }).eq("id", rfqid);

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function sendRecipients(e) {
    const { recipients } = e.detail;

    await addQuoteRequestRecipients({
      id: rfqid,
      recipients,
      sender_id: $profile.id,
    });

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
    orgs.fetchOne($org.id);
  }

  async function updateRfq(update) {
    await api.from("quote_requests").update(update).eq("id", rfqid);

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function updateJob(update) {
    await api.from("jobs").update(update).eq("id", rfq.job_id);

    Object.entries(update).forEach(([key, value]) => {
      rfq.job[key] = value;
    });

    await rfqs.fetchOne(rfqid);
  }

  async function sendRfq(e) {
    rfqs.spin(rfqid);

    await sendQuoteRequest({
      id: rfqid,
      recipients: rfq.data.saved_recipients,
      sender_id: $profile.id,
      job_id: rfq.job_id,
      organization_id: $org.id,
    });

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
    orgs.fetchOne($org.id);
  }

  async function cancelQuote(e) {
    const { id } = e.detail;
    await api.from("quotes").delete().eq("id", id);

    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function cancelRfq() {
    await cancelQuoteRequest({ id: rfqid });
    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function update() {
    rfq = await fetchRfq(rfqid);
    rfqs.fetchOne(rfqid);
  }

  async function downloadQuoteFiles() {
    const file = rfq.quotes[0]?.data.attachments?.[0];
    if (!file) return;
    const { data, error } = await api.storage.from("quotes").download(file.path);
    if (error) {
      Bugsnag.notify(error);
      return;
    }

    downloadBlob(data, file.name);
  }

  onMount(async () => {
    if (!rfqid) return;

    eb.on("download-quote-files", downloadQuoteFiles);

    try {
      rfq = await fetchRfq(rfqid);

      if (rfq.group_id) {
        const gd = await fetchGroup(rfq.group_id);
        const g = createGroup(gd);
        group = g;
      } else {
        const gd = await fetchGroup(rfq.job.current_group_id);
        const g = createGroup(gd);
        group = g;
      }

      if ($suppliers.length) {
        let supplier = $suppliers.find((s) => s.id === rfq?.data.saved_recipients?.[0]?.contact_id);
        if (!supplier) supplier = $suppliers[0];
        $currentSupplierid = supplier.id;

        if (!rfq?.data.saved_recipients?.length) {
          const recipient = {
            type: "org",
            contact_id: supplier.id,
            primary_contact_id: null,
            company_name: supplier.name,
            name: null,
            email: supplier.primary_contact_email,
          };

          if (!isEqual([recipient], rfq?.data.saved_recipients)) {
            setRecipient({ detail: { recipient } });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  });

  onDestroy(() => {
    eb.unsubscribe("download-quote-files", downloadQuoteFiles);
  });
</script>

{#if rfq}
  <div class="w-full space-y-4">
    {#if rfq.notifications.length}
      <div class="px-4">
        <Notifications notifications={rfq.notifications} on:clear={update} />
      </div>
    {/if}

    <div class="content-columns px-4">
      <div class="left-column space-y-2">
        <SelectInput
          placeholder="Assigned to"
          labelWidth="1rem"
          value={rfq.assigned_to_id}
          options={assignToOptions}
          outerBorder={false}
          on:input={(e) => updateRfq({ assigned_to_id: e.detail.value })}>
          <div class="text-black" slot="label">
            <ProfileIcon />
          </div>
          <div slot="selected" let:option class="flex items-center gap-1">
            {#if option.initials}
              <div
                class="profile-circle relative"
                style="background-color: {option.color || '#000'}; border-color: {option.color || '#000'}">
                {option.initials}
              </div>
            {/if}
            <div class:text-gray-400={!option.value}>
              {option.label}
            </div>
          </div>
          <div slot="option" let:option class="flex items-center gap-1">
            {#if option.initials}
              <div
                class="profile-circle relative"
                style="background-color: {option.color || '#000'}; border-color: {option.color || '#000'}">
                {option.initials}
              </div>
            {:else}
              <div class="text-gray-400">
                <ProfileIcon />
              </div>
            {/if}
            <div class:text-gray-400={!option.value}>
              {option.label}
            </div>
          </div>
        </SelectInput>
        <TextInput
          labelWidth="1rem"
          value={rfq.job.customer_text}
          on:input={(e) => updateJob({ customer_text: e.detail.value })}
          outerBorder={false}
          placeholder="Customer"
          disabled={rfq.sent_at}>
          <div class="text-black" slot="label">
            <ProfileIcon />
          </div>
        </TextInput>
        <MapboxInput
          labelWidth="1rem"
          value={rfq.job.location}
          coordinates={rfq.job.location_coordinates}
          outerBorder={false}
          placeholder="Location"
          disabled={rfq.sent_at}
          on:input={(e) =>
            updateJob({
              location: e.detail.value,
              location_coordinates: e.detail.coordinates,
              location_postal_code: e.detail.postal_code,
              location_country_code: e.detail.country_code,
            })}>
          <div class="text-black" slot="label">
            <LocationIcon />
          </div>
        </MapboxInput>
        <!-- <SelectInput
          labelWidth="1rem"
          outerBorder={false}
          value={rfq.request_for}
          disabled={rfq.sent_at}
          options={[
            { label: "For quote", value: "quote" },
            { label: "For order", value: "order" },
            { label: "For other (see notes)", value: "other" },
          ]}
          on:input={(e) => updateRfq({ request_for: e.detail.value })}>
          <div class="text-black" slot="label">
            <TagIcon />
          </div>
        </SelectInput> -->
        <TextInput
          labelWidth="1rem"
          value={$group?.seller_reference}
          outerBorder={false}
          disabled
          placeholder="PO Number">
          <div class="text-black" slot="label">
            <NumberIcon />
          </div>
        </TextInput>
        <SelectInput
          labelWidth="1rem"
          outerBorder={false}
          value={rfq.job.product_list_id}
          disabled={rfq.sent_at}
          options={$productLists.map((pl) => ({ label: pl.name, value: pl.id }))}
          on:input={(e) => updateJob({ product_list_id: e.detail.value })}>
          <div class="text-black text-center" slot="label">$</div>
        </SelectInput>
        <ContenteditableInput
          placeholder="Notes"
          labelWidth="1rem"
          value={rfq.job.notes}
          outerBorder={false}
          disabled={rfq.sent_at}
          on:input={(e) => updateJob({ notes: e.detail.value })}>
          <div class="text-black" slot="label">
            <NotesIcon />
          </div>
        </ContenteditableInput>
        <GroupAttachments
          {group}
          disabled={rfq.sent_at || rfq.status === "canceled"}
          attachments={allAttachments?.none} />
      </div>

      <div class="right-column text-xs space-y-2">
        {#if !rfq.sent_at && rfq.status !== "canceled"}
          <RecipientsInput
            labelWidth="5rem"
            org={$org}
            {recipients}
            editable
            on:remove-recipient={removeReipient}
            on:add-recipient={confirmAddRecipients}
            on:set-recipient={setRecipient} />
        {/if}
        {#if rfq.sent_at}
          <div class="space-y-2">
            {#if rfq.quotes.length === 0}
              <div class="italic border rounded p-4 b-2">No quotes defined</div>
            {/if}
            {#each rfq.quotes as quote (quote.id)}
              <Quote {rfq} {quote} on:cancel={cancelQuote} />
            {/each}
          </div>
          {#if $org.account_type !== "free"}
            <div class="flex justify-end">
              <button class="item-list-button" on:click={() => eb.dispatch("open-recipient-modal")}>
                <CirclePlusIcon />
                <div>Add Recipient</div>
              </button>
            </div>
          {/if}
        {/if}
      </div>

      <div class="center-column space-y-2">
        {#if $group}
          <ProductSummaryTable
            recordId={rfqid}
            recordType="rfq"
            sent={rfq.sent_at}
            {group}
            {types}
            {items}
            {org}
            quote={firstQuote}
            job={rfq.job} />
        {/if}
      </div>
    </div>
  </div>

  <QuoteRecipientModal
    bind:this={qrModal}
    sent={rfq.sent_at}
    {rfq}
    org={$org}
    on:add-recipients={addRecipients}
    on:send-to-recipients={sendRecipients} />

  {#if !rfq.sent_at}
    <ProductSendModal
      on:confirm={sendRfq}
      {rfq}
      {group}
      {recipients}
      {items}
      {types}
      org={$org}
      on:update-rfq={(e) => updateRfq(e.detail)}
      on:update-job={(e) => updateJob(e.detail)} />
  {/if}

  <Modal
    bind:this={cancelRfqModal}
    closeable
    on:confirm={cancelRfq}
    buttons={[
      { label: "Cancel", type: "cancel" },
      { label: "Cancel RFQ", type: "confirm", style: "danger" },
    ]}>
    <div slot="title">Cancel RFQ</div>
    <div slot="content" class="space-y-4">
      <div>
        <div>Are you sure you want to cancel this RFQ?</div>
      </div>
      <div class="font-bold">
        {rfq.job.name}
      </div>
    </div>
  </Modal>

  <DownloadModal title="Download Contents" {group} {rfq} {types} name={rfq.job.name} />
{/if}

<style lang="scss">
  .content-columns {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    overflow: hidden;

    .left-column,
    .right-column {
      @apply text-xs;
      width: 100%;
    }

    @media (min-width: 768px) {
      .left-column,
      .right-column {
        width: calc(50% - 0.25rem);
      }
    }

    .center-column {
      @apply grow overflow-x-hidden;
    }
  }

  @media (min-width: 1636px) {
    .content-columns {
      flex-wrap: nowrap;

      .left-column,
      .right-column {
        width: 20rem;
      }

      .right-column {
        order: 1;
      }
    }
  }
</style>
